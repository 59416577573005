import Vue from "vue";
import vuex from "vuex";
import pathify from "./pathify";
import { make } from "vuex-pathify";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);
const state = {
  user: null,
  username: null,
  token: null,
  providers: null,
};
const mutations = make.mutations(state);
const store = new vuex.Store({
  state,
  mutations,
  plugins: [
    pathify.plugin,
    createPersistedState({
      key: "akattopup",
      storage: window.localStorage,
    }),
  ],
});

global.store = store;
export default store;
