import dayjs from "dayjs";
import * as XLSX from "xlsx";

export default {
  data: function () {
    return {
      AGENTLEVELS: [
        { code: "1", name: "Level 1" },
        { code: "2", name: "Level 2" },
        { code: "3", name: "Level 3" },
        { code: "4", name: "Level 4" },
      ],
      AGENTTYPES: [
        { code: "SHOP", name: "ຮ້ານຄ້າ" },
        { code: "UNIT", name: "ຫົວໜ້າໜ່ວຍ" },
      ],
      ROLES: [
        { code: "SHOP", name: "ຮ້ານຄ້າ" },
        { code: "UNIT", name: "ຫົວໜ້າໜ່ວຍ" },
        { code: "ADMIN", name: "ແອັດມິນ" },
        { code: "SUPERADMIN", name: "ແອັດມິນໃຫຍ່" },
      ],
      USERSTATUSES: [
        { code: "ACTIVE", name: "Active" },
        { code: "INACTIVE", name: "Inactive" },
      ],
    };
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    displaydigit(x, ishide) {
      if (!x) return "";
      if (x.length === 1) x = `0${x}`;
      return (ishide ? "******" : x).substring(0, x.length);
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return dayjs(x).format("HH:mm:ss");
      }
      return x;
    },
  },
  methods: {
    excel(table, filename) {
      let ws = XLSX.utils.table_to_sheet(table);
      let wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, `${filename}.xlsx`);
    },
    getvalue(items, value) {
      const item = items.find((item) => item.code === value);
      if (item) return item.name;
      else return value;
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "";
    },
    datetime(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return dayjs(x).format("DD/MM/YYYY");
      }
      return x;
    },

    generateRandom(characters, length) {
      let result = "";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    randomCharacter(length) {
      return this.generateRandom(
        `ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789`,
        length
      );
    },
    randomNumber(length) {
      return this.generateRandom(`0123456789`, length);
    },

    isnumber(str) {
      return /^\d+$/.test(str);
    },

    sum(items, key) {
      let output = 0;
      items.forEach((item) => {
        output += item[key] * 1;
      });
      return output;
    },
  },
};
